class DataApi {
    constructor() {
        this.base_url = this.get_base_url();
        this.session_url = `${this.get_base_url()}/api/session`;
        this.session_terminate_url = `${this.get_base_url()}/api/terminate/session`;
        this.myself_form_url = `${this.get_base_url()}/api/store/myself_form`;
        this.create_payment_url = `${this.get_base_url()}/api/create/payment`;
        this.successful_payment_url = `${this.get_base_url()}/api/payment/successful`;
        this.check_purchased_url = `${this.get_base_url()}/api/check/purchased`;
        this.gift_form_url = `${this.get_base_url()}/api/store/gift_form`;
        this.send_to_zen_url = `${this.get_base_url()}/api/sendtozen`; // todo: is this used?
        this.send_to_zen_desk = `${this.get_base_url()}/api/sendtozendesk`; // Valid API
        this.update_zen_url = `${this.get_base_url()}/api/updatezen`; // API not implemented
        this.get_from_zen_url=`${this.get_base_url()}/api/getzendata`;
        this.create_stripe_customer=`${this.get_base_url()}/api/customer/create`;
    }

    async get_from_zen(userData){
        const response = await fetch(this.get_from_zen_url, {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(userData),
            credentials: 'include',
            
        })
        // console.log(response)
        return await response
      }

     async send_to_zen(userData) {

       
        const response = await fetch(this.send_to_zen_url, {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(userData),
            credentials: 'include',
            
        })
        // console.log(response)
        return await response
    }

    
    // api not implemented on backend
    async update_zen(userData) {
        const response = await fetch(this.update_zen_url, {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(userData),
            credentials: 'include'
        })
        return await response.json();
    }

    async store_myself_form(data) {
        const response = await fetch(this.myself_form_url, {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(data),
            credentials: 'include'
        })
        return response;
    }

    async create_payment(item_name, customer_id, purchase_type, start_date="", client_now="") {
        const response = await fetch(this.create_payment_url, {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ item: { name: item_name }, customer_id: customer_id, purchase_type: purchase_type, start_date: start_date, client_now: client_now }),
            credentials: 'include'
        })
        return await response.json();
    }

    async store_successful_payment(payment_object) {
        const response = await fetch(this.successful_payment_url, {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(payment_object),
            credentials: 'include'
        })
        return await response.json();
    }

    async store_gift_form(gift_form) {
        const response = await fetch(this.gift_form_url, {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(gift_form),
            credentials: 'include'
        })
        return await response.json();
    }

    async get_session() {
        const response = await fetch(this.session_url, { 
            credentials: 'include' ,  
        } );
        return response;

    }

    async terminate_session() {
        const response = await fetch(this.session_terminate_url, {
            credentials: 'include' ,
        } );
        return response;
    }

    async check_purchased(email) {
        const response = await fetch(this.check_purchased_url, {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ email: email }),
            credentials: 'include'
        })
        return response.json();
    }

    async stripe_customer_create(user_data) {
        const response = await fetch(this.create_stripe_customer, {
            method: 'post',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify(user_data),
            credentials: 'include'
        })
        return response.json();
    }

    get_base_url() {
        if (document.URL.indexOf('localhost') > -1) {
            return "http://localhost:5000";
        } else {
            return `${document.location.protocol}//${document.location.hostname}`
        }
    }

    async global_terminate_session() {
      let cookies = document.cookie.split(";");
      //console.log(cookies);
      await this.terminate_session().then((resp) => {
        document.cookie = 'name=session_id; expires=Sun, 06 May 1980 12:00:00 UTC';
        this.get_session().then((resp1) => {
          console.log("New session started")
        });
      });
    }

}

export default new DataApi();