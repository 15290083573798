<template>
  <div class="top--banner">
    <p class="top--banner-sub">
      All year round tech support for you or your family for $199!
      <button @click="$router.push('purchase')">Buy Now</button>
    </p>
  </div>
</template>

<script>
export default {
  name: "TopBanner",
};
</script>
