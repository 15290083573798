<template>
  <div class="gift">
    <Header></Header>
    <div class="dorks--container">
      <div class="gift--main">
        <div class="gift--main-header">
          <h1 class="gift--main-header-title">You've been gifted a Dork!</h1>
          <p class="gift--main-header-text">
            Wow, nice! You've just been gifted a Dork, giving you access to remote,
            friendly tech support. Complete the fields below to complete your registration.
          </p>
        </div>

        <div class="gift--form-container">
          <form>
            <div class="dorks--form-group">
              <label for="first-name">First name <span> *</span></label>
              <input
                  v-model="first_name"
                  type="text"
                  id="first-name"
                  placeholder="Taylor"
                  required
                  class="dorks--form-control"
              />
            </div>

            <div class="dorks--form-group">
              <label for="last-name">Last name <span> *</span></label>
              <input
                  v-model="last_name"
                  type="text"
                  id="last-name"
                  placeholder="Smith"
                  required
                  class="dorks--form-control"
              />
            </div>

            <div class="dorks--form-group">
              <label for="email">Email address <span> *</span></label>
              <input
                  v-model="email"
                  type="email"
                  id="email"
                  placeholder="taylorsmith@youremail.com"
                  required
                  class="dorks--form-control"
              />
            </div>

            <div class="dorks--form-group">
              <label for="mobile-number">Phone number</label>
              <input
                  v-model="mobile_number"
                  type="tel"
                  id="mobile-number"
                  placeholder="Enter phone number"
                  required
                  class="dorks--form-control"
              />
            </div>

            <div class="dorks--form-tos">
              <input type="checkbox" id="tos-checkbox" v-model="tos_checked"/>
              <label for="tos--checkbox"
              >I agree to the Dorks <a href="#">Terms</a> &
                <a href="#">Privacy Policy</a>
              </label>
            </div>
            <button
                @click.prevent="complete_registration"
                class="gift--main-btn btn grey-btn"
                :disabled="registration_form_disabled"
            >
              Complete Registration
            </button>
          </form>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <img
        :src="require('@/assets/img/backgrounds/background-eyes.svg')"
        class="background-img"
        alt="star-icon"
    />
    <img
        :src="require('@/assets/img/backgrounds/background-eyes-2.svg')"
        class="background-img-alt"
        alt="star-icon"
    />
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import data_api from "@/store/data_api";
import intlTelInput from "intl-tel-input";

export default {
  name: "Purchase",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      mobile_number: "",
      tos_checked: false,
      userResponse: "",
      zenEndpointUsers: "https://dorks.zendesk.com/api/v2/users",
      subscription: '',
      id: ''
    };
  },
  computed: {
    form_active: function () {
      return this.$store.getters.get_form_active;
    },
    registration_form_disabled: function () {
      return (
          !this.first_name.length > 0 ||
          !this.last_name.length > 0 ||
          !this.email.length > 0 ||
          !this.mobile_number.length > 0 ||
          !this.tos_checked
      );
    },
  },
  methods: {
    complete_registration: function () {

      let name = this.first_name + " " + this.last_name
      let email = this.email
      let phone = this.mobile_number
      let purchase_term = this.subscription
      let subscription_id = this.id


      const userData = {
        name: name,
        email: email,
        phone: phone,
        purchase_term: purchase_term,
        subscription_id: subscription_id
      }

      const path = data_api.get_base_url() + '/api/sendtozen';

      this.axios.post(path, userData)
          .then((response) => {
            this.$router.push("/purchase/result/gift-received");
          })
          .catch((error) => {
            // console.log(error);
            alert("Error: Already Registered")
          })
    },
  },
  mounted() {
    // this.getGiftedUser();
    this.subscription = this.$route.params.subscription;
    this.id = this.$route.params.id
    const input = document.querySelector("#mobile-number");
    intlTelInput(input, {});
  },
};
</script>
