<template>
  <footer class="footer">
    <div class="dorks--container">
      <div class="footer--main">
        <p class="footer--text">&copy; {{ new Date().getFullYear() }} Dorks Inc. All rights reserved.</p>
        <div class="footer--social">
          <a href="https://www.twitter.com/suchdorks" target="_blank">
            <font-awesome-icon
                :icon="['fab', 'twitter']"
                class="fa-lg"
            ></font-awesome-icon>
          </a>
          <a href="https://www.linkedin.com/suchdorks" target="_blank">
            <font-awesome-icon
                :icon="['fab', 'linkedin']"
                class="fa-lg"
            ></font-awesome-icon>
          </a>
          <a href="https://www.instagram.com/suchdorks" target="_blank">
            <font-awesome-icon
                :icon="['fab', 'instagram']"
                class="fa-lg"
            ></font-awesome-icon>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  mounted() {

  }
};
</script>
