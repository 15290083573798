import { library } from '@fortawesome/fontawesome-svg-core';
import { faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueGrid from '@liqueflies/vue-flex-grid';
import axios from 'axios';
import { BootstrapVue } from 'bootstrap-vue';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import vueCountryRegionSelect from 'vue-country-region-select';
import { Column, Hidden, Row } from 'vue-grid-responsive';
import VueSimpleCheckbox from 'vue-simple-custom-checkbox';
import App from './App.vue';
import './assets/scss/main.scss';
import router from './router';
import store from './store';
 
Vue.use(VueGrid)
Vue.use(VueSimpleCheckbox)

library.add(faTwitter, faLinkedin, faInstagram, faSpinner);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false
Vue.use(BootstrapVue);
Vue.use(vueCountryRegionSelect);
Vue.component('row', Row);
Vue.component('column', Column);
Vue.component('hidden', Hidden);
Vue.use(VueAxios, axios)


// Vue.http.headers.common['Access-Control-Allow-Origin'] = '*';

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
