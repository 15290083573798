<template>
<div class="dorks--nav">
  <div class="dorks--container">
    <header class="header">
      <a @click="$router.push('/')">
        <img :src="require('@/assets/img/logo.svg')" alt="logo" />
      </a>
      <div
        class="normal-nav"
        >
        <a
            class="btn btn-xl white-btn"
            href="https://dorks.zendesk.com/hc/en-us/requests/new"
            target="_blank"
        >
            Contact Us
        </a>
      </div>
      <button
        class="hamburger-button"
        @click="mobile_nav = !mobile_nav"
      >
      </button>
    </header>
  </div>
  <div v-if="mobile_nav" class="dorks-mobile--nav">
    <button
        class="btn btn-xl white-btn"
        @click="$router.push('purchase')"
      >
        Contact Us
      </button>
  </div>
</div>
</template>

<script>
export default {
  name: "Header",
  mounted() {
    $(window).scroll(function(e){ 
        var $el = $('.dorks--nav'); 
        var isPositionFixed = ($el.css('position') == 'fixed');
        var elementHeight = $el.offsetHeight;
        if ($(this).scrollTop() > elementHeight && !isPositionFixed){ 
            $el.css({'position': 'fixed', 'top': '0px'}); 
        }
        if ($(this).scrollTop() < elementHeight && isPositionFixed){
            $el.css({'position': 'static', 'top': '0px'}); 
        } 
    });
  },
  data() {
    return {
      mobile_nav: false
    }
  },
};
</script>
