<template>
  <section class="DorksScroller">
    <article>
      <div>
        <ul>
          <li>
            <img
                :src="require('@/assets/img/dorks/dork-1.svg')"
                alt="Dork 1"
            />
          </li>
          <li>
            <img
                :src="require('@/assets/img/dorks/dork-2.svg')"
                alt="Dork 2"
            />
          </li>
          <li>
            <img
                :src="require('@/assets/img/dorks/dork-3.svg')"
                alt="Dork 3"
            />
          </li>
          <li>
            <img
                :src="require('@/assets/img/dorks/dork-4.svg')"
                alt="Dork 4"
            />
          </li>
          <li>
            <img
                :src="require('@/assets/img/dorks/dork-5.svg')"
                alt="Dork 5"
            />
          </li>
          <li>
            <img
                :src="require('@/assets/img/dorks/dork-6.svg')"
                alt="Dork 6"
            />
          </li>
          <li>
            <img
                :src="require('@/assets/img/dorks/dork-7.svg')"
                alt="Dork 7"
            />
          </li>
          <li>
            <img
                :src="require('@/assets/img/dorks/dork-8.svg')"
                alt="Dork 8"
            />
          </li>
          <li>
            <img
                :src="require('@/assets/img/dorks/dork-9.svg')"
                alt="Dork 9"
            />
          </li>
        </ul>
      </div>

      <div>
        <ul>
          <li>
            <img
                :src="require('@/assets/img/dorks/dork-1.svg')"
                alt="Dork 1"
            />
          </li>
          <li>
            <img
                :src="require('@/assets/img/dorks/dork-2.svg')"
                alt="Dork 2"
            />
          </li>
          <li>
            <img
                :src="require('@/assets/img/dorks/dork-3.svg')"
                alt="Dork 3"
            />
          </li>
          <li>
            <img
                :src="require('@/assets/img/dorks/dork-4.svg')"
                alt="Dork 4"
            />
          </li>
          <li>
            <img
                :src="require('@/assets/img/dorks/dork-5.svg')"
                alt="Dork 5"
            />
          </li>
          <li>
            <img
                :src="require('@/assets/img/dorks/dork-6.svg')"
                alt="Dork 6"
            />
          </li>
          <li>
            <img
                :src="require('@/assets/img/dorks/dork-7.svg')"
                alt="Dork 7"
            />
          </li>
          <li>
            <img
                :src="require('@/assets/img/dorks/dork-8.svg')"
                alt="Dork 8"
            />
          </li>
          <li>
            <img
                :src="require('@/assets/img/dorks/dork-9.svg')"
                alt="Dork 9"
            />
          </li>
        </ul>
      </div>
    </article>
  </section>
</template>

<script>
export default {
  name: "DorksScroller",
  props: {
    items: {}
  },
  methods: {},
  mounted() {
  }
};
</script>
