<template>
  <div class="purchase--main" :class="form_active !== 0 ? 'second--form' : ''">
    <Header></Header>
    <section class="dorks--container">
      <div class="purchase--page">
        <div class="purchase--page-header">
          <h2 class="purchase--page-header-title">Purchase Dorks</h2>
          <p class="purchase--page-header-text">
            Get Dorks for yourself or gift it to someone you care enough about that needs it.
          </p>
        </div>
        <div class="purchase--form">
          <div class="purchase--button-container">
            <button
              @click="$store.commit('set_form_active', 0)"
              class="purchase--option-btn btn"
              :class="{ active: form_active === 0 }"
            >
              For Myself
            </button>
            <button
              @click="$store.commit('set_form_active', 1)"
              class="purchase--option-btn btn"
              :class="{ active: form_active === 1 }"
            >
              Gift Someone
            </button>
          </div>
          {{ get_myself_form }}
          {{ get_gift_form }}
          <div class="purchase--form-container">
            <div class="form--container">
              <form v-if="form_active === 0" @submit.prevent>
                <div class="form-group"> 
                    <label for="first-name">First name<span> *</span></label> 
                    <input
                        v-model="first_name"
                        type="text"
                        class="form-control form-control-lg"
                        id="first-name"
                        placeholder="Head"
                        required
                    />
                </div> 

                <div class="form-group">
                  <label for="last-name">Last name<span> *</span></label>
                  <input
                    v-model="last_name"
                    type="text"
                    class="form-control"
                    id="last-name"
                    placeholder="Master"
                    required
                  />
                </div>

                <div class="form-group">
                  <label for="email">Your email address<span> *</span></label>
                  <input
                    v-model="email"
                    type="email"
                    class="form-control"
                    id="email"
                    placeholder="grumpyteacher@school.com"
                    required
                  />
                </div>
                <div>
                  <button
                    v-if="purchase_loading"
                    type="submit"
                    @click.prevent="purchase_dorks"
                    class="purchase--button"
                    :disabled="myself_form_disabled"
                  >
                    <font-awesome-icon
                      :icon="['fa', 'spinner']"
                      class="fa-spin"
                    ></font-awesome-icon>
                  </button>
                  <button
                    v-else
                    type="submit"
                    @click.prevent="purchase_dorks"
                    :class="myself_form_disabled ? 'purchase--button grey-btn' : 'purchase--button'"
                    :disabled="myself_form_disabled"
                  >
                    Go to Checkout
                  </button>
                </div>
              </form>

              <form v-else-if="form_active === 1" @submit.prevent>
                <div class="form-group">
                  <label for="gift-from-name"
                    >Your name <span> *</span></label
                  >
                  <input
                    v-model="gift_from_name"
                    type="text"
                    id="gift-from-name"
                    placeholder="Generous Unicorn"
                    class="form-control"
                    required
                  />
                </div>

                <div class="form-group">
                  <label for="gift-from-email"
                    >Your email address <span> *</span></label
                  >
                  <input
                    v-model="gift_from_email"
                    type="text"
                    id="gift-from-email"
                    placeholder="Email address"
                    class="form-control"
                    required
                  />
                </div>

                <div class="form-group">
                  <label for="gift-receiver"
                    >Who are you gifting it to? <span> *</span></label
                  >
                  <input
                    v-model="gift_receiver_name"
                    type="text"
                    id="gift-receiver"
                    placeholder="Lucky Person"
                    class="form-control"
                    required
                  />
                </div>

                <div class="form-group">
                  <label for="gift-receiver-email">
                    Gift recipient email address?<span> *</span></label
                  >
                  <input
                    v-model="gift_receiver_email"
                    type="text"
                    id="gift-receiver-email"
                    placeholder="Email address"
                    required
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label>Personal Message<span> *</span></label>
                  <textarea
                    v-model="gift_receiver_message"
                    placeholder="Add your gift message here which will be included on the gift recipient's confirmation email ..."
                    id="custom-message"
                    class="form-control textarea"
                  ></textarea>
                </div>
                <div class="form-group">
                  <label for="gift-start-date">
                    When would you like your gift sent?<span> *</span></label
                  >
                  <input
                    v-model="gift_start_date"
                    type="date"
                    id="gift-start-date"
                    placeholder=""
                    required
                    class="form-control"
                  />
                </div>
                <div>
                  <button
                    v-if="purchase_loading"
                    type="submit"
                    @click.prevent="gift_dorks"
                    class="purchase--button"
                    :disabled="gift_form_disabled"
                  >
                    <font-awesome-icon
                      :icon="['fa', 'spinner']"
                      class="fa-spin"
                    ></font-awesome-icon>
                  </button>
                  <button
                    v-else
                    type="submit"
                    @click.prevent="gift_dorks"
                    :class="gift_form_disabled ? 'purchase--button grey-btn' : 'purchase--button'"
                    :disabled="gift_form_disabled"
                  >
                    Go to Checkout
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ple />
    </section>
    <Footer></Footer>
    <img
        :src="require('@/assets/img/backgrounds/background-eyes.svg')"
        class="background-img"
        alt="star-icon"
    />
    <img
        :src="require('@/assets/img/backgrounds/background-eyes-2.svg')"
        class="background-img-alt"
        alt="star-icon"
    />
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import actions_enum from "@/store/actions_enum";
import data_api from "@/store/data_api";
export default {
  name: "Purchase",
  components: {
    Header,
    Footer,
  },
  mounted() {
    window.scrollTo(0, 0);
    window.addEventListener("resize", this.set_dims);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.set_dims);
    // console.log("Resize destroyed");
  },
  data() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      myself_checkbox: false,
      gift_checkbox: false,
      gift_from_name: "",
      gift_from_email: "",
      gift_receiver_name: "",
      gift_receiver_email: "",
      gift_receiver_message: "",
      gift_start_date: "",
      current_width: window.innerWidth,
      current_height: window.innerHeight,
      purchase_loading: false,
    };
  },
  computed: {
    form_active: function () {
      // console.log(this.$store.getters.get_form_active);
      return this.$store.getters.get_form_active;
    },
    myself_form_disabled: function () {
      return (
        !this.first_name.length > 0 ||
        !this.last_name.length > 0 ||
        !this.email.length > 0
      );
      // || !this.myself_checkbox;
    },
    gift_form_disabled: function () {
      return (
        !this.gift_from_name ||
        !this.gift_from_email ||
        !this.gift_receiver_name ||
        !this.gift_receiver_email ||
        !this.gift_receiver_message ||
        !this.gift_start_date
      );
      // || !this.gift_checkbox;
    },
    is_mobile: function () {
      // console.log(this.current_width, this.current_height);
      return this.current_width < 768;
    },
    get_myself_form: function () {
      let purchase_form = this.$store.getters.get_purchase_form;
      this.first_name = purchase_form.first_name;
      this.last_name = purchase_form.last_name;
      this.email = purchase_form.email;
    },
    get_gift_form: function () {
      let gift_form = this.$store.getters.get_gift_form;
      this.gift_from_name = gift_form.from_name;
      this.gift_from_email = gift_form.from_email;
      this.gift_receiver_name = gift_form.to_name;
      this.gift_receiver_email = gift_form.to_email;
      this.gift_receiver_message = gift_form.to_message;
      this.gift_start_date = gift_form.start_date;
    },
  },
  methods: {

    valid_email: function (email) {
      let email_re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return email_re.test(email);
    },
    valid_date: function (start_date) {
      let start = new Date(start_date+'T00:00:01');
      if(start) {
        let today = new Date();
        today.setHours(0,0,0,1);
        return start >= today;
      }
      return false;
    },
    validate_myself_form: function (myself_form) {
      let [valid, error_message] = [true, ""];
      if (myself_form.first_name.length === 0) {
        [valid, error_message] = [false, "First name is required"];
      } else if (myself_form.last_name.length === 0) {
        [valid, error_message] = [false, "Last name is required"];
      } else if (!this.valid_email(myself_form.email)) {
        [valid, error_message] = [false, "Please enter a valid email"];
        // } else if ( ! myself_form.tos) {
        //     [valid, error_message] = [false, "Please accept terms of service"]
      }
      return [valid, error_message];
    },
    validate_gift_form: function (gift_form) {
      let [valid, error_message] = [true, ""];
      if (gift_form.to_name.length === 0) {
        [valid, error_message] = [false, "Recipient name is required"];
      } else if (gift_form.to_message.length === 0) {
        [valid, error_message] = [false, "The message is required!"];
      } else if (!this.valid_email(gift_form.to_email.trim()) || !this.valid_email(gift_form.from_email.trim())) {
        [valid, error_message] = [false, "Please enter a valid email"];
      } else if (!this.valid_date(gift_form.start_date)) {
        [valid, error_message] = [false, "Please enter a date in the future"];
      }
      return [valid, error_message];
    },

    gift_dorks: function () {
      if (this.gift_form_disabled) {
        // console.log("form disabled");
      } else {
        let id = Math.random().toString(36).substr(2, 9);
        let gift_form = {
          id: id,
          from_name: this.gift_from_name,
          from_email: this.gift_from_email.trim(),
          to_email: this.gift_receiver_email.trim(),
          to_name: this.gift_receiver_name,
          to_message: this.gift_receiver_message,
          start_date: this.gift_start_date,
          client_now: this.$store.getters.get_client_now,
          // tos: this.gift_checkbox
        };
        let [valid, error_message] = this.validate_gift_form(gift_form);
        if (valid) {
          this.purchase_loading = true;
          data_api
            .store_gift_form(gift_form)
            .then((resp) => {
              // console.log(resp);

              if (resp.hasOwnProperty("result") && resp.result) {
                 this.$store.commit("gift_form", gift_form);
                this.$router.push("/checkout");
              } else if (
                resp.hasOwnProperty("error") &&
                resp.error === "gifted"
              ) {
                alert("Cannot send gift to user with active plan");
              }
            })
            .finally(() => {
              this.purchase_loading = false;
            });
        } else {
          alert(error_message);
        }
      }
    },

    purchase_dorks: function () {
      if (this.myself_form_disabled) {
        // console.log("form disabled");
      } else {
        // console.log("this runnin");
        let myself_form_data = {
          first_name: this.first_name.trim(),
          last_name: this.last_name.trim(),
          email: this.email.trim(),
          last_action: actions_enum.ACTION_PURCHASE,
          // tos: this.myself_checkbox,
        };

        let [valid, error_message] =
          this.validate_myself_form(myself_form_data);

        if (valid) {
          this.purchase_loading = true;
          data_api
            .store_myself_form(myself_form_data)
            .then((resp) => {
              resp.json().then((data) => {
                // console.log(data);
                if (data.success) {
                  this.$store.commit("purchase_form", myself_form_data);
                  this.$router.push("/checkout");
                } else {
                  if (
                    data.hasOwnProperty("error") &&
                    data.error === "purchased"
                  ) {
                    alert("An active account is associated with this email");
                  }
                }
              });
            })
            .finally(() => {
              this.purchase_loading = false;
            });
        } else {
          alert(error_message);
        }
      }
    },
    set_dims: function () {
      this.current_width = window.innerWidth;
      this.current_height = window.innerHeight;
    },
  },
};
</script>
