<template>
  <div class="">
    <Header></Header>
    <div class="dorks--container">
      <div class="purchase--result">
        <div
          v-if="$route.params.who === 'myself-semi-yearly'"
          class="purchase--result-container"
        >
            <img
                :src="require('@/assets/img/dorks/dork_myself.svg')"
                alt="star-icon"
            />
          <h1 class="purchase--result-title">Dorks, purchased!</h1>
          <p class="purchase--result-text">
            Your tech support is now live. Contact your Dork through
            our dedicated channels whenever you need it. Congratulations!
          </p>
          <div class="purchase--result-btn-main">
            <button class="purchase--result-btn  green-btn" @click.prevent="goToZenDesk">
              Contact your Dork
            </button>
          </div>
        </div>
        <div
          v-if="$route.params.who === 'myself-yearly'"
          class="purchase--result-container"
        >
            <img
                :src="require('@/assets/img/dorks/dork_myself.svg')"
                alt="star-icon"
            />
          <h1 class="purchase--result-title">Dorks, purchased!</h1>
          <p class="purchase--result-text">
            Your tech support is now live. Contact your Dork through
            our dedicated channels whenever you need it. Congratulations!
          </p>
          <div class="purchase--result-btn-main">
            <button class="purchase--result-btn  green-btn" @click.prevent="goToZenDesk">
              Contact your Dork
            </button>
          </div>
        </div>
        <div
          v-if="$route.params.who === 'gift-received'"
          class="purchase--result-container"
        >
            <img
                :src="require('@/assets/img/dorks/dork_myself.svg')"
                alt="star-icon"
            />
          <h1 class="purchase--result-title">Dorks, redeemed!</h1>
          <p class="purchase--result-text">
            Your tech support is now live. Contact your Dork through
            our dedicated channels whenever you need it. Congratulations!
          </p>
          <div class="purchase--result-btn-main">
            <button class="purchase--result-btn  green-btn" @click.prevent="goToZenDesk">
              Contact your Dork
            </button>
          </div>
        </div>
        <div
          v-else-if="$route.params.who === 'gift-semi-yearly'"
          class="purchase--result-container"
        >
            <img
                :src="require('@/assets/img/dorks/dork_gift.svg')"
                alt="star-icon"
            />
          <h1 class="purchase--result-title">Dorks, gifted!</h1>
          <p class="purchase--result-text">
            You’ve successfully gifted someone a Dork! They
            will notified to setup an account with us on the day you specified. Feel free to reach out to
            them to get them started!
          </p>
          <div class="purchase--result-btn-main">
            <button class="purchase--result-btn  green-btn" @click.prevent="goToHome">
              Take me back home
            </button>
          </div>
        </div>
        <div
          v-else-if="$route.params.who === 'gift-yearly'"
          class="purchase--result-container"
        >
            <img
                :src="require('@/assets/img/dorks/dork_gift.svg')"
                alt="star-icon"
            />
          <h1 class="purchase--result-title">Dorks, gifted!</h1>
          <p class="purchase--result-text">
            You’ve successfully gifted someone a Dork for a whole year! They
            will notified to setup an account with us on the day you specified. Feel free to reach out to
            them to get them started!
          </p>
          <div class="purchase--result-btn-main">
            <button class="purchase--result-btn  green-btn" @click.prevent="goToHome">
              Take me back home
            </button>
          </div>
        </div>
        <div
          v-else-if="$route.params.who === 'registered'"
          class="purchase--result-container"
        >
            <img
                :src="require('@/assets/img/dorks/dork_registered.svg')"
                alt="star-icon"
            />
          <h1 class="purchase--result-title">Registration Complete!</h1>
          <p class="purchase--result-text">
            Congratulations, you've got Dorks! Gifts will be automatically get emailed.
            If you've purchased a Personal plan, contact your Dork whenever your need us.
          </p>
          <div class="purchase--result-btn-main">
            <button class="purchase--result-btn  green-btn" @click.prevent="goToZenDesk">
              Contact your Dork
            </button>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <img
        :src="require('@/assets/img/backgrounds/background-eyes.svg')"
        class="background-img"
        alt="star-icon"
    />
    <img
        :src="require('@/assets/img/backgrounds/background-eyes-2.svg')"
        class="background-img-alt"
        alt="star-icon"
    />
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import data_api from "@/store/data_api";
export default {
  name: "PurchaseResult",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      current_width: window.innerWidth,
      current_height: window.innerHeight,
    };
  },
  created() {
    // Force the session to end so the user can have a clean experience next time
    this.endSession();
  },
  mounted() {
    window.addEventListener("resize", this.set_dims);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.set_dims);
  },
  computed: {
    is_mobile: function () {
      return this.current_width < 768;
    },
  },
  methods: {
    set_dims: function () {
      this.current_width = window.innerWidth;
      this.current_height = window.innerHeight;
    },

    goToZenDesk: function () {
      window.open("https://dorks.zendesk.com", "_blank");
    },

    goToHome: function () {
      window.open('/',  "_self");
    },

    endSession: async function () {
      let cookies = document.cookie.split(";");
      //console.log(cookies);
      await data_api.terminate_session().then((resp) => {
        document.cookie = 'name=session_id; expires=Sun, 06 May 1980 12:00:00 UTC';
        data_api.get_session().then((resp1) => {
          console.log("New session started")
        });
      });
    },
  },
};

</script>
