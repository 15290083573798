<template>
    <div class="dorks--accordian">
        <div v-for="(item, index) in items" :key="item.name" :class="[(itemIndex === index && toggleAccordian) ? 'accordian--item accordian--open' : 'accordian--item accordian--closed']">
            <div class="accordian--title"  @click="toggleAccordianItems(index)">
                <h3>{{item.name}}</h3>
                <img
                    :src="require('@/assets/img/eyecons/Eyecons-open.svg')"
                    alt="toggle-icon"
                    class="toggle-icon-open"
                />
                <img
                    :src="require('@/assets/img/eyecons/Eyecons-closed.svg')"
                    alt="toggle-icon"
                    class="toggle-icon-closed"
                />
            </div>
            <div class="accordian--body">
                {{item.text}}
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "Accordian",
  props: {
      items: {}
  },
  methods: {
    toggleAccordianItems(i) {
      this.itemIndex = i;
      this.toggleAccordian = !this.toggleAccordian;
    }
  },
  mounted() {
  },
  data() {
    return {
      itemIndex: '',
      toggleAccordian: false
    }
  },
};
</script>
