import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import data_api from "@/store/data_api";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: null,
        sidenav_open: true,
        form_active: 0,
        purchase_result: '',
        first_name: '',
        last_name: '',
        email: '',
        to_email: '',
        to_name: '',
        to_message: '',
        customer_id: '',
        from_name: '',
        from_email: '',
        start_date: '',
        name_on_card: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
        addressLine1: '',
        addressLine2: '',
    },

    mutations: {
        set_user(state, user) {
            state.user = user;
        },
        set_form_active(state, btn_active) {
            state.form_active = btn_active;
        },
        purchase_form(state, myself_form) {
            state.first_name = myself_form.first_name;
            state.last_name = myself_form.last_name;
            state.email = myself_form.email;
        },
        gift_form(state, gift_form) {
            state.from_name = gift_form.from_name;
            state.from_email = gift_form.from_email;
            state.to_email = gift_form.to_email;
            state.to_name = gift_form.to_name;
            state.to_message = gift_form.to_message;
            state.start_date = gift_form.start_date;
        },
        checkout_form(state, checkout_form) {
            state.name_on_card = checkout_form.name_on_card;
            state.country = checkout_form.country;
            state.address_line1 = checkout_form.addressLine1;
            state.address_line2 = checkout_form.addressLine2;
            state.city = checkout_form.city;
            state.usstate = checkout_form.usstate;
            state.zipcode = checkout_form.zipcode;
        },
        update_state(state, payload) {
            for (let key in payload) {
                if (state.hasOwnProperty(key)) {
                    state[key] = payload[key]
                }
            }
        },
        update_first_name(state, first_name) {
            state.first_name = first_name;
        },
        update_last_name(state, last_name) {
            state.last_name = last_name
        },
        update_email(state, email) {
            state.email = email
        },
        update_customer_id(state, customer_id) {
            state.customer_id = customer_id
        },
        update_from_name(state, from_name) {
            state.from_name = from_name
        },
        update_from_email(state, from_email) {
            state.from_email = from_email
        },
        update_start_date(state, start_date) {
            state.start_date = start_date
        },
        update_name_on_card(state, name_on_card) {
            state.name_on_card = name_on_card
        },
        update_city(state, city) {
            state.city = city
        },
        update_usstate(state, usstate) {
            state.name_on_card = usstate
        },
        update_zipcode(state, zipcode) {
            state.zipcode = zipcode
        },
        update_country(state, country) {
            state.country = country
        },
        update_addressLine1(state, addressLine1) {
            state.addressLine1 = addressLine1
        },
        update_addressLine2(state, addressLine2) {
            state.addressLine2 = addressLine2
        },
    },

    actions: {
        logout({commit}) {
            commit('set_user', null);
        },
        login({commit}, user) {
            commit('set_user', user);
        },
    },

    modules: {

    },

    getters: {
        get_form_active(state) {
            return state.form_active;
        },
        get_purchase_form(state) {
            return {first_name: state.first_name, last_name: state.last_name,
                name_on_card: state.first_name + ' ' + state.last_name,
                email: state.email, country: state.country,
                addressLine1:state.addressLine1, addressLine2: state.addressLine2, city: state.city,
                state: state.state, zipcode: state.zipcode}
        },
        get_gift_form(state) {
            return {from_email: state.from_email, from_name: state.from_name, to_email: state.to_email,
                to_name: state.to_name, to_message: state.to_message, start_date: state.start_date,
                client_now: state.client_now}
        },
        get_state_exists(state, key) {
            return state.hasOwnProperty(key)
        },
        get_state(state) {
            return state;
        },
        get_email(state) {
            return state.email
        },
        get_customer_id(state) {
            return state.customer_id
        },
        get_start_date(state) {
            return state.start_date
        }
    },

})