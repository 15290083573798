<template>
  <div>
    <Header></Header>
    <div class="dorks--container">
      <div class="stripe--checkout">
        <div class="stripe--checkout-header">
          <h2 class="stripe--checkout-header-title">Checkout</h2>
          <p class="stripe--checkout-header-text">
            Activate Dorks and get unlimited friendly technical support at your fingertips.
          </p>
        </div>
        <div class="stripe--checkout-main">
            <div v-if="semiYearlySubscription == true">
              <div class="payment--monthly payment--active">
                <div class="payment--monthly-left">
                  <div class="payment--monthly-main">
                    <h3 class="payment--monthly-main-title">Semi Yearly Plan <span>$99 for 6 months</span></h3>
                    <p class="payment--monthly-main-text">
                      Unlimited requests for a single user. Submit requests by email, phone or chat. No contracts, cancel anytime.
                    </p>
                  </div>
                </div>
                <div class="payment--monthly-right">
                  <input
                    type="checkbox"
                    value="false"
                    v-model="semiYearlySubscription"
                  />
                </div>
              </div>
            </div>
            <div v-else>
              <div class="payment--monthly">
                <div class="payment--monthly-left">
                  <div class="payment--monthly-main">
                    <h3 class="payment--monthly-main-title">Semi Yearly Plan <span>$99 for 6 months</span></h3>
                    <p class="payment--monthly-main-text">
                      Unlimited requests for a single user. Submit requests by email, phone or chat. No contracts, cancel anytime.
                    </p>
                  </div>
                </div>
                <div class="payment--monthly-right">
                  <input
                    type="checkbox"
                    value="true"
                    @change="toggleYearly"
                    v-model="semiYearlySubscription"
                  />
                </div>
              </div>
            </div>

            <div v-if="yearlySubscription == true">
              <div class="payment--yearly payment--active">
                <div class="payment--yearly-left">
                  <div class="payment--yearly-plan">
                    <h3 class="payment--yearly-plan-title">Yearly Plan <span>$199 per year</span></h3>
                  </div>
                  <p class="payment--yearly-text">
                    2 users included! Unlimited requests for up to two users. Submit requests by email, phone or chat. No contracts, cancel anytime.
                  </p>
                </div>
                <div class="payment--yearly-right">
                  <input
                    type="checkbox"
                    value="false"
                    v-model="yearlySubscription"
                  />
                </div>
              </div>
            </div>
            <div v-else>
              <div class="payment--yearly">
                <div class="payment--yearly-left">
                  <div class="payment--yearly-plan">
                    <h3 class="payment--yearly-plan-title">Yearly Plan <span>$199 per year</span></h3>
                  </div>
                  <p class="payment--yearly-text">
                    2 users included! Unlimited requests for up to two users. Submit requests by email, phone or chat. No contracts, cancel anytime.
                  </p>
                </div>
                <div class="payment--yearly-right">
                  <input
                    type="checkbox"
                    value="true"
                    @change="toggleSemiYearly"
                    v-model="yearlySubscription"
                  />
                </div>
              </div>
            </div>
            <div class="stripe--checkout-form" @submit.prevent>
              <form class="stripe-form">
                <input
                  v-model="email"
                  :disabled="form_active === 0"
                  id="checkout_email"
                  type="hidden"
                />
                <div class="form-group">
                  <label for="aio-card"
                    >Card Information
                    <span> *</span>
                  </label>
                  <div id="aio-card" class="dorks--input-card form-control"></div>
                  <!--<StripeElementCard :pk="publishableKey"></StripeElementCard>-->
                </div>

                <div class="complex-client-name-form">
                    <div class="form-group">
                        <label for="checkout_name">Name on card<span>*</span></label>
                        <input
                            v-model="name_on_card"
                            id="checkout_name"
                            type="text"
                            placeholder="Name as it appears"
                            class="form-control"
                            required
                        />
                    </div>
                </div>

                <div class="complex-addr-form">
                  <div class="form-group">
                    <label for="country-select-component"
                      >Billing Address<span>*</span>
                    </label>
                    <div class="ghost-select">
                        <country-select
                            id="country-select-component"
                            class=""
                            v-model="country"
                            :country="country"
                            topCountry="US"
                        />
                    </div>
                  </div>

                  <div class="form-group">
                    <input
                      v-model="addressLine1"
                      type="text"
                      class="form-control"
                      id="address1"
                      placeholder="Address line 1"
                      required
                    />
                  </div>

                  <div class="form-group">
                    <input
                      v-model="addressLine2"
                      type="text"
                      class="form-control"
                      id="address2"
                      placeholder="Address line 2"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      v-model="city"
                      type="text"
                      id="city"
                      class="form-control city--control"
                      placeholder="City"
                      required
                    />
                  </div>
                  <div class="stripe--checkout-row">
                    <div class="form-group">
                      <input
                        v-model="usstate"
                        type="text"
                        id="state"
                        placeholder="State"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="form-group">
                      <input
                        v-model="zipcode"
                        type="text"
                        id="zip"
                        placeholder="Zip Code"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div v-if="checkout_error" class="checkout-error">
                  {{ checkout_error }}
                </div>
                <!-- <StripeElementCard pk="this.pk" :change="this.card_change" class="stripe-card"></StripeElementCard>-->

                <button
                  v-if="checkout_loading"
                  type="submit"
                  @click.prevent="checkout_submit"
                  class="stripe--checkout-btn"
                  :disabled="checkout_disabled"
                >
                  <font-awesome-icon
                    :icon="['fa', 'spinner']"
                    class="fa-spin"
                  ></font-awesome-icon>
                </button>
                <button
                  v-else
                  type="submit"
                  @click.prevent="checkout_submit"
                  :disabled="checkout_disabled"
                  :class="checkout_disabled ? 'stripe--checkout-btn grey-btn' : 'stripe--checkout-btn'"
                >
                  Purchase Dorks
                </button>
              </form>
            </div>
            <div class="stripe--checkout-footer">
              <p>
                By placing your order, you agree to our
                <a href="#" class="address-text"> terms of service </a>
                and
                <a href="#" class="address-text"> privacy policy </a>
                By confirming, you allow Dorks, Inc. to charge your card for
                this payment and future payments in accordance with their terms.
              </p>
            </div>
        </div>
      </div>
      {{ load_form_data }}
    </div>
    <Footer></Footer>
    <img
        :src="require('@/assets/img/backgrounds/background-eyes.svg')"
        class="background-img"
        alt="star-icon"
    />
    <img
        :src="require('@/assets/img/backgrounds/background-eyes-2.svg')"
        class="background-img-alt"
        alt="star-icon"
    />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header";
import data_api from "@/store/data_api";
import { StripeCheckout, StripeElementCard } from "@vue-stripe/vue-stripe";
import VueSimpleCheckbox from "vue-simple-custom-checkbox";
export default {
  components: {
    StripeCheckout,
    StripeElementCard,
    Header,
    Footer,
    VueSimpleCheckbox,
  },

  data() {
    return {
      stripe: Stripe(process.env.VUE_APP_STRIPE_PK),
      pk: `${process.env.VUE_APP_STRIPE_PK}`,
      from_name: "",
      from_email: "",
      email: "",
      to_email: "",
      to_message: "",
      to_name: "",
      name_on_card: "",
      addressLine1: "",
      addressLine2: "",
      country: "",
      city: "",
      usstate: "",
      zipcode: "",
      stripe_secret: "",
      stripe_secret_yearly: "",
      stripe_secret_semi_yearly: "",
      stripe_secret_pending_semi_yearly: "",
      stripe_secret_pending_yearly: "",
      stripe_subscription_id: "",
      checkout_disabled: true,
      checkout_error: "",
      checkout_loading: false,
      card: null,
      subscription: "SemiYearly",
      yearlySubscription: false,
      semiYearlySubscription: true,
      //zendesk Endpoint
      zenEndpointUsers: "https://dorks.zendesk.com/api/v2/users",
      customer_id: "",
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    window.addEventListener("resize", this.set_dims);
    let self = this;
    //this.create_payment();
    //this.create_payment_semiyearly();
    let elements = this.stripe.elements();
    this.card = elements.create("card", {
        style: {
            base: {
                fontSize: '16px',
            },
        },
    });
    this.card.mount("#aio-card");
    this.card.on("change", this.card_change);
    document
      .querySelector(".stripe-form")
      .addEventListener("submit", self.checkout_submit);

    let gift_form = this.$store.getters.get_gift_form;
    // console.log("yoyo" + gift_form.to_email);

    //Dropdown JS
    var x, i, j, l, ll, selElmnt, a, b, c;
    /*look for any elements with the class "custom-select":*/
    x = document.getElementsByClassName("ghost-select");
    l = x.length;

    for (i = 0; i < l; i++) {
        selElmnt = x[i].getElementsByTagName("select")[0];
        ll = selElmnt.length;
        /*for each element, create a new DIV that will act as the selected item:*/
        a = document.createElement("DIV");
        a.setAttribute("class", "select-selected");

        if( selElmnt.value ) {
            a.classList.add( 'selected' );
        }
    
        var _html = '<div class="item-data">';
            if( selElmnt.options[selElmnt.selectedIndex].getAttribute( 'data-icon' ) ) {
                _html += '<i class="'+selElmnt.options[selElmnt.selectedIndex].getAttribute( 'data-icon' )+'"></i>';
            }
            else if( selElmnt.options[selElmnt.selectedIndex].getAttribute( 'data-image' ) ) {
                _html += '<img src="'+selElmnt.options[selElmnt.selectedIndex].getAttribute( 'data-image' )+'" />';
            }
            _html += '<div class="item-content">';
                _html += selElmnt.options[selElmnt.selectedIndex].innerHTML;
                if( selElmnt.options[selElmnt.selectedIndex].getAttribute( 'data-username' ) ) {
                    _html += '<p class="username">'+selElmnt.options[selElmnt.selectedIndex].getAttribute( 'data-username' )+'</p>';
                }
            _html += '</div>';
        _html += '</div>';

        a.innerHTML = _html;

        x[i].appendChild(a);
        /*for each element, create a new DIV that will contain the option list:*/
        b = document.createElement("DIV");
        b.setAttribute("class", "select-items select-hide");

        for (j = 1; j < ll; j++) {
            /*for each option in the original select element,
            create a new DIV that will act as an option item:*/
            c = document.createElement("DIV");

            if( selElmnt.options[j].disabled == true ) {
                c.classList.add( 'disabled' );
            }

            var _list_html = '<div class="item-data">';
                if( selElmnt.options[j].getAttribute( 'data-icon' ) ) {
                    _list_html += '<i class="'+selElmnt.options[j].getAttribute( 'data-icon' )+'"></i>';
                }
                else if( selElmnt.options[j].getAttribute( 'data-image' ) ) {
                    _list_html += '<img src="'+selElmnt.options[j].getAttribute( 'data-image' )+'" />';
                }
                _list_html += '<div class="item-content" data-value="'+selElmnt.options[j].getAttribute( 'value' )+'">';
                _list_html += selElmnt.options[j].innerHTML;
                if( selElmnt.options[j].getAttribute( 'data-username' ) ) {
                    _list_html += '<p class="username">'+selElmnt.options[j].getAttribute( 'data-username' )+'</p>';
                }
                _list_html +=  '</div>';
            _list_html += '</div>';
            c.innerHTML = _list_html;

            if( selElmnt.options[j].getAttribute( 'value' ) == selElmnt.value ) {
                c.classList.add( 'same-as-selected' );
            }
            // c.innerHTML = selElmnt.options[j].innerHTML;
            c.addEventListener("click", function(e) {
                /*when an item is clicked, update the original select box,
                and the selected item:*/
                var y, i, k, s, h, sl, yl;
                s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                sl = s.length;
                h = this.parentNode.previousSibling;

                for (i = 0; i < sl; i++) {
                    if (s.options[i].getAttribute( 'value' ) == this.querySelector( '.item-content' ).getAttribute( 'data-value' ) ) {
                        s.selectedIndex = i;
                        h.innerHTML = this.innerHTML;
                        y = this.parentNode.getElementsByClassName("same-as-selected");
                        yl = y.length;
                        for (k = 0; k < yl; k++) {
                            // y[k].removeAttribute("class");
                            y[k].classList.remove( 'same-as-selected' );
                        }
                        this.setAttribute("class", "same-as-selected");
                        h.classList.add("selected");

                        break;
                    }
                }
                h.click();
            });

            b.appendChild(c);
        }

        x[i].appendChild(b);
        a.addEventListener("click", function(e) {
            /*when the select box is clicked, close any other select boxes,
            and open/close the current select box:*/
            e.stopPropagation();
            closeAllSelect(this);
            this.nextSibling.classList.toggle("select-hide");
            this.classList.toggle("select-arrow-active");
            
        });
    }

    function closeAllSelect(elmnt) {
        /*a function that will close all select boxes in the document,
        except the current select box:*/
        var x, y, i, xl, yl, arrNo = [];
        x = document.getElementsByClassName("select-items");
        y = document.getElementsByClassName("select-selected");
        xl = x.length;
        yl = y.length;
        for (i = 0; i < yl; i++) {
            if (elmnt == y[i]) {
                arrNo.push(i)
            } else {
                y[i].classList.remove("select-arrow-active");
            }
        }
        for (i = 0; i < xl; i++) {
            if (arrNo.indexOf(i)) {
                x[i].classList.add("select-hide");
            }
        }
    }
    /*if the user clicks anywhere outside the select box,
    then close all select boxes:*/
    document.addEventListener("click", closeAllSelect);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.set_dims);
  },

  methods: {
    toggleYearly: function() {
      this.yearlySubscription = false;
    },

    toggleSemiYearly: function() {
      this.semiYearlySubscription = false;
    },

    checkout_submit: async function(event) {
      this.checkout_loading = true;
      event.preventDefault();
      let form_active = this.$store.getters.get_form_active;
      let checkout_form = {
          name_on_card: this.name_on_card,
          country: document.getElementById('country-select-component').value,
          address_line1: this.addressLine1,
          address_line2: this.addressLine2,
          city: this.city,
          usstate: this.usstate,
          zipcode: this.zipcode,
      }
      // validate the checkout form
      let [valid, error_message] = this.validate_checkout_form(checkout_form);
      if (!valid) {
        console.log(error_message);
        this.checkout_error = error_message;
        this.checkout_loading = false;
      } else {
        // if form is purchase form
        if (form_active === 0) {
          let email = this.$store.getters.get_email;
          let self = this;

          // check if the user has already purchased a personal plan
          data_api.check_purchased(email).then(async (result) => {
            if (result.hasOwnProperty("result") && result.result) {
              self.checkout_loading = false;
              this.checkout_error = "You have already purchased Dorks!";
            } else {
              // get or create a customer
              await self.create_customer(checkout_form).then((result) => {
                if(!result.has_error){
                  if (self.semiYearlySubscription) {
                  self.create_payment_semiyearly('personal').then(async (result) => {
                    if (result.error) {
                      self.checkout_loading = false;
                      this.checkout_error = result.error;
                    } else {
                      await self.confirm_card_payment({action: "purchase_dorks"}).then((result) => {
                        if (result.error) {
                          self.checkout_loading = false;
                          this.checkout_error = result.error;
                        } else {
                          this.sendToZen('personal', 'semiyearly');
                          data_api.global_terminate_session()
                        }
                      });
                    }
                  });
                }
                if (self.yearlySubscription) {
                  self.create_payment('personal').then((result) => {
                    if (result.error) {
                      self.checkout_loading = false;
                      this.checkout_error = result.error;
                    } else {
                      self.confirm_card_payment({action: "purchase_dorks"}).then((result) => {
                        if (result.error) {
                          self.checkout_loading = false;
                          this.checkout_error = result.error;
                        } else {
                          this.sendToZen('personal', 'yearly');
                          data_api.global_terminate_session()
                        }
                      });
                    }
                  });
                }
                }

              });
            }
          });
          // if form is gift_form
        } else {
          let self = this;
          //todo: at this point we need to create a customer or get a reference to the customer
          await self.create_customer(checkout_form).then((result) => {
            if(!result.has_error) {
              if (self.semiYearlySubscription) {
                self.create_payment_semiyearly('gift').then((result) => {
                  if (result.error) {
                    self.checkout_loading = false;
                    this.checkout_error = result.error;
                  } else {
                    self.confirm_card_payment({action: "gift_dorks"}).then((result) => {
                      if (result.error) {
                        self.checkout_loading = false;
                        this.checkout_error = result.error;
                      } else {
                        this.sendToZen('gift', 'semiyearly');
                        data_api.global_terminate_session()
                      }
                    });
                  }
                });
              }
              if (self.yearlySubscription) {
                self.create_payment('gift').then((result) => {
                  if (result.error) {
                    self.checkout_loading = false;
                    this.checkout_error = result.error;
                  } else {
                    self.confirm_card_payment({action: "gift_dorks"}).then((result) => {
                      if (result.error) {
                        self.checkout_loading = false;
                        this.checkout_error = result.error;
                      } else {
                        this.sendToZen('gift', 'yearly');
                        data_api.global_terminate_session()
                      }
                    });
                  }
                });
              }
            }
          });
        }
      }
    },

    create_customer: async function (checkout_form) {

      let form_active = this.$store.getters.get_form_active;
      // if form is purchase form
      if (form_active === 0) {
        // creating a customer for self purchase
        const path = data_api.create_stripe_customer;
        let name =
            this.$store.state.first_name + " " + this.$store.state.last_name;
        let email = this.$store.state.email;

        let userData = {
          name: name,
          email: email,
          to_name: '',
          to_email: '',
          address_line1: checkout_form.address_line1,
          address_line2: checkout_form.address_line2,
          city: checkout_form.city,
          usstate: checkout_form.usstate,
          country: checkout_form.country,
          zipcode: checkout_form.zipcode,
          purchase_type: 'self',
        }

        let stripe_customer = await data_api.stripe_customer_create(userData).then((response) =>{
          console.log(response);
          if(response.id) {
            this.customer_id = response.id;
            return {'has_error': false}
          }
          else {
            this.checkout_error = response.error;
            this.checkout_loading = false;
            return {'has_error': true}
          }
        });
        return stripe_customer;
      } else {
        const path = data_api.create_stripe_customer;
        // this is a gift form
        let from_name =
            this.$store.state.from_name;
        let from_email = this.$store.state.from_email;

        let to_name = this.$store.state.to_name;
        let to_email = this.$store.state.to_email;
        let to_message = this.$store.state.to_message;
        let userData = {
          name: from_name,
          email: from_email,
          to_name: to_name,
          to_email: to_email,
          to_message: to_message,
          address_line1: checkout_form.address_line1,
          address_line2: checkout_form.address_line2,
          city: checkout_form.city,
          usstate: checkout_form.usstate,
          country: checkout_form.country,
          zipcode: checkout_form.zipcode,
          purchase_type: 'gift',
        }

        let stripe_customer = await data_api.stripe_customer_create(userData).then((response) =>{
          console.log(response);
          if(response.id) {
            this.customer_id = response.id;
            return {'has_error': false}
          }
          else {
            this.checkout_error = response.error;
            return {'has_error': true}
          }
        });
        return stripe_customer;
      }
    },

    save_future_gift: async function(form_active, start_date, client_now, payload) {
      let self = this;
      let card_payment_confirmed = {};
      let router_self, router_gift, card_secret, subscription_type = null;
      if (self.stripe_secret_pending_semi_yearly) {
        card_secret = self.stripe_secret_pending_semi_yearly
        subscription_type = "SemiYearly"
        router_self = "/purchase/result/myself-semi-yearly";
        router_gift = "/purchase/result/gift-semi-yearly";
      }
      if (self.stripe_secret_pending_yearly) {
        card_secret = self.stripe_secret_pending_yearly
        subscription_type = "Yearly"
        router_self = "/purchase/result/myself-yearly";
        router_gift = "/purchase/result/gift-yearly";
      }

      card_payment_confirmed = self.stripe.confirmCardSetup(card_secret, {
        payment_method: {
          card: self.card,
          billing_details: {
            name: self.name_on_card,
            email: self.from_email,
          }
        }
      }).then(async function (result) {
        if (result.error) {
          self.checkout_error = result.error.message;
          return {'error': result.error.message}
        } else {
          let id = Math.random().toString(36).substr(2, 9);
          result["action"] = payload["action"];
          result["name_on_card"] = self.name_on_card;
          result["email"] = self.email;
          result["from_name"] = self.from_name;
          result["from_email"] = self.from_email;
          result["to_email"] = self.to_email;
          result["to_name"] = self.to_name;
          result["to_message"] = self.to_message;
          result["subscription"] = subscription_type;
          result["start_date"] = start_date;
          result["client_now"] = client_now;
          result["id"] = id;
          result["stripe_subscription_id"] = self.stripe_subscription_id;

          let store_pmt = await data_api.store_successful_payment(result).then((data) => {
            // // console.log("checkout response", data);
            if (data.result) {
              self.$router.push(
                  form_active === 1
                      ? router_gift
                      : router_self
              );
              return data;
            } else if (data.error) {
              self.checkout_error = data.error;
              return {'error': data.error}
            }
          });
          return store_pmt;
        }
      }).finally((s) => {
        self.checkout_loading = false;
      });
      return card_payment_confirmed;
    },

    confirm_card_payment: async function (payload = undefined) {
      let self = this;
      let form_active = this.$store.getters.get_form_active; //0 - personal //1- gift
      let purchase_type = ""
      if(form_active === 0) {
        purchase_type = "self"
      }
      else {
        purchase_type = "gift"
      }

      let start_date = this.$store.getters.get_start_date;
      let client_now = this.client_date();
      let secret, router_self, router_gift, yearly_or_semi_subscription_description = null;
      let card_payment_confirmed = {};

      if (self.yearlySubscription === true) {
        secret = self.stripe_secret_yearly;
        router_self = "/purchase/result/myself-yearly";
        router_gift = "/purchase/result/gift-yearly";
        yearly_or_semi_subscription_description = "Yearly"
      }

      if (self.semiYearlySubscription === true) {
        secret = self.stripe_secret_semi_yearly;
        router_self = "/purchase/result/myself-semi-yearly";
        router_gift = "/purchase/result/gift-semi-yearly";
        yearly_or_semi_subscription_description = "SemiYearly"
      }

      if ((self.stripe_secret_pending_semi_yearly || self.stripe_secret_pending_yearly) && purchase_type === "gift") {
        // Future dated gifts
        card_payment_confirmed = await self.save_future_gift(form_active, start_date, client_now, payload)
      } else {
        // Personal purchases or immediate gifts
        card_payment_confirmed = await self.stripe
          .confirmCardPayment(secret, {
            payment_method: {card: self.card},
          })
          .then(async function (result) {
            if (result.error) {
              // Show error to your customer
              self.checkout_error = result.error.message;
              return {'error': result.error.message}
            } else {
              result["action"] = payload["action"];
              result["name_on_card"] = self.name_on_card;
              result["stripe_subscription_id"] = self.stripe_subscription_id;
              if (purchase_type === 'gift') {
                let id = Math.random().toString(36).substr(2, 9);
                result["id"] = id;
                result["from_name"] = self.from_name;
                result["from_email"] = self.from_email;
                result["to_email"] = self.to_email;
                result["to_name"] = self.to_name;
                result["to_message"] = self.to_message;
                result["subscription"] = yearly_or_semi_subscription_description;
                result["start_date"] = start_date;
                result["client_now"] = client_now;
              }
              if (purchase_type === "self") {
                result["email"] = self.email;
              }
              //todo: make sure this api returns an object
              let store_pmt = await data_api.store_successful_payment(result).then((data) => {
                if (data.result) {
                  self.$router.push(
                      purchase_type === 'self'
                          ? router_self
                          : router_gift
                  );
                  return data;
                } else if (data.error) {
                  self.checkout_error = data.error;
                  return {'error': data.error}
                }
              });
              return store_pmt;
            }
          })
          .finally((s) => {
            self.checkout_loading = false;
          });

      }
      return card_payment_confirmed;
    },

    client_date: function() {
      let date = new Date()
      let day = date.getDate();
      let month = date.getMonth()+1;
      let year = date.getFullYear();
      return `${year}-${month}-${day}`;
    },

    create_payment: async function(purchase_type = undefined) {
      let start_date = this.$store.state.start_date;
      let client_now = this.client_date();

      let payment_secret_yearly = await data_api.create_payment(
          "dorks_annual", this.customer_id, purchase_type, start_date, client_now
      );
      if(payment_secret_yearly.error) {
        return payment_secret_yearly;
      }

      this.stripe_subscription_id = payment_secret_yearly.subscriptionId

      if(payment_secret_yearly.charge === 'now') {
        this.stripe_secret_yearly = payment_secret_yearly.secret;
      }
      else if(payment_secret_yearly.charge === 'later') {
        this.stripe_secret_pending_yearly = payment_secret_yearly.secret;
      }
      return payment_secret_yearly;
    },

    create_payment_semiyearly: async function(purchase_type = undefined) {
      let start_date = this.$store.state.start_date;
      let client_now = this.client_date();
      let payment_secret_semi_yearly = await data_api.create_payment(
        "dorks_semiyearly", this.customer_id, purchase_type, start_date, client_now
      );
      if(payment_secret_semi_yearly.error) {
          return payment_secret_semi_yearly;
      }

      this.stripe_subscription_id = payment_secret_semi_yearly.subscriptionId
      if(payment_secret_semi_yearly.charge === 'now') {
        this.stripe_secret_semi_yearly = payment_secret_semi_yearly.secret;
      }
      else if(payment_secret_semi_yearly.charge === 'later') {
        this.stripe_secret_pending_semi_yearly = payment_secret_semi_yearly.secret;
      }
      return payment_secret_semi_yearly;
    },

    sendToZen: function(purchase_type = undefined, purchase_term = undefined) {
      let form_active = this.$store.getters.get_form_active;

      let name =
        this.$store.state.first_name + " " + this.$store.state.last_name;
      let email = this.$store.state.email;
      let customer_id = this.customer_id;
      let from_name = this.$store.state.from_name;
      let from_email = this.$store.state.from_email;
      let to_name = this.$store.state.to_name;
      let to_email = this.$store.state.to_email;
      let start_date = this.$store.state.start_date;

      const userData = {
            form_active: form_active,
            yearly_subscription: this.yearlySubscription,
            purchase_type: purchase_type,
            purchase_term: purchase_term,
            email: email,
            name: name,
            from_name: from_name,
            from_email: from_email,
            to_name: to_name,
            to_email: to_email,
            customer_id: customer_id,
            start_date: start_date,
      };

      const path = data_api.send_to_zen_desk;

      this.axios
        .post(path, userData)
        .then(() => {
           console.log("record added to zendesk");
        })
        .catch((error) => {
           console.log(error);
        });

    },

    card_change: function(event) {
      // Disable the Pay button if there are no card details in the Element
      this.checkout_disabled = event.empty;
      this.checkout_error = event.error ? event.error.message : "";
    },

    set_dims: function() {
      this.current_width = window.innerWidth;
      this.current_height = window.innerHeight;
    },

    checkout: async function() {},

    validate_checkout_form: function (form) {
      let [valid, error_message] = [true, ""];
      if(form.name_on_card.length === 0) {
        [valid, error_message] = [false, "Name on card is required"];
      } else if(form.country.length === 0) {
        [valid, error_message] = [false, "Country is required"];
      } else if(form.address_line1.length === 0) {
        [valid, error_message] = [false, "Address is required"];
      } else if(form.city.length === 0) {
        [valid, error_message] = [false, "City is required"];
      } else if(form.usstate.length === 0) {
        [valid, error_message] = [false, "State is required"];
      } else if(form.zipcode.length === 0) {
        [valid, error_message] = [false, "Zip Code is required"];
      }
      return [valid, error_message];
    },
  },

  computed: {
    get_gift_form: function() {
      let gift_form = this.$store.getters.get_gift_form;
      this.to_name = gift_form.to_name;
      this.to_email = gift_form.to_email;
      this.to_message = gift_form.to_message;
    },

    is_mobile: function() {
      // console.log(this.current_width, this.current_height);
      return this.current_width < 768;
    },

    load_form_data: function() {
      let form_active = this.$store.getters.get_form_active;
      if (form_active === 0) {
        let purchase_form = this.$store.getters.get_purchase_form;
        if (purchase_form.email) {
          this.email = purchase_form.email;
        }
        if (purchase_form.first_name.trim() && purchase_form.last_name.trim()) {
          this.name_on_card =
            purchase_form.first_name + " " + purchase_form.last_name;
        }
      } else if (form_active === 1) {
        let gift_form = this.$store.getters.get_gift_form;

        if (gift_form.to_email) {
          this.email = gift_form.by_email;
          this.to_email = gift_form.to_email;
          this.to_message = gift_form.to_message;
          this.to_name = gift_form.to_name;
        }
      }
    },
    form_active: function() {
      return this.$store.getters.get_form_active;
    },

  },
};
</script>
