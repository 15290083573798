<template>
  <div>
    <Header></Header>
    <main class="dorks--home">
      <!-- coding for section one -->
      <div class="dorks--container">
        <section class="dorks--hero">
          <div>
            <h2>
              Are you tired of being tech support?
            </h2>
            <p>
              Everyone needs a Dork in the family. Get remote tech support for yourself, your parents or a loved one. Unlimited tech support by phone, chat and email to help solve thousands of technical issues.
            </p>
            <button
                class="btn btn-xl green-btn"
                @click="$router.push('purchase')"
            >
              Purchase Dorks
            </button>
          </div>
        </section>
      </div>
      <div class="dorks--carousel">
        <DorksScroller></DorksScroller>
      </div>
      <div class="dorks--container">
        <div class="dorks--special">
          <div>
            <h2>Our special abilities</h2>
            <p>
              From home automation, to file backups, fraud prevention and more — Dorks can help! We provide support for all sorts of devices, including Apple, Android, Amazon, Windows, Samsung and more.
            </p>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-4 dork--ability">
                <div class="content-img">
                    <img
                        :src="require('@/assets/img/eyecons/Eyecons.svg')"
                        alt="wrench"
                    />
                </div>
                <div class="content-title">Devices</div>
                <div class="content-text">
                  Get technical support for your phones, <span>cloud storage</span>, and even home appliances, like <span>routers</span>, televisions, and more.
                </div>
            </div>
            <div class="col-md-6 col-lg-4 dork--ability">
                <div class="content-img">
                    <img
                        :src="require('@/assets/img/eyecons/Eyecons-1.svg')"
                        alt="wrench"
                    />
                </div>
                <div class="content-title">Protect</div>
                <div class="content-text">
                  Carefully manage <span>fraud prevention</span>, password help, <span>anti-virus</span>, privacy & best practices to reduce theft and fraud.
                </div>
            </div>
            <div class="col-md-6 col-lg-4 dork--ability">
                <div class="content-img">
                    <img
                        :src="require('@/assets/img/eyecons/Eyecons-2.svg')"
                        alt="wrench"
                    />
                </div>
                <div class="content-title">Setup</div>
                <div class="content-text">
                  <span>Setting up software</span>, installations, managing your home technology, <span>parental controls</span> for all devices.
                </div>
            </div>
            <div class="col-md-6 col-lg-4 dork--ability">
                <div class="content-img">
                    <img
                        :src="require('@/assets/img/eyecons/Eyecons-3.svg')"
                        alt="wrench"
                    />
                </div>
                <div class="content-title">Speed</div>
                <div class="content-text">
                  Improve computer speeds, <span>internet speeds</span>, router issues, <span>free up memory</span>, and optimize performance for everything.
                </div>
            </div>
            <div class="col-md-6 col-lg-4 dork--ability">
                <div class="content-img">
                    <img
                        :src="require('@/assets/img/eyecons/Eyecons-4.svg')"
                        alt="wrench"
                    />
                </div>
                <div class="content-title">Automation</div>
                <div class="content-text">
                  Establish home commands, voice commands, smart appliances and make your life easier with <span>Alexa</span> and <span>Google Assistant</span>.
                </div>
            </div>
            <div class="col-md-6 col-lg-4 dork--ability">
                <div class="content-img">
                    <img
                        :src="require('@/assets/img/eyecons/Eyecons-5.svg')"
                        alt="wrench"
                    />
                </div>
                <div class="content-title">Backups</div>
                <div class="content-text">
                  Cherish your memories and create more room on your devices with the <span>best backup solutions</span> -- hardware and software.
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="faq-wrapper">
        <div class="dorks--container">
            <div class="dorks--faq-container">
                    <div class="dorks--faq">
                        <div>
                            <span>Support</span>
                            <h2>FAQs</h2>
                            <p>
                              Learn everything you need to know about Dorks. We offer fast, friendly and unlimited tech support. Have more questions, chat or email us at such@dorks.co.
                            </p>
                        </div>
                        <div>
                            <Accordian :items="accordianItems"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import TopBanner from "@/components/TopBanner";
import carousel from 'vue-owl-carousel';
import Accordian from "@/components/Accordian";
import DorksScroller from "@/components/DorksScroller";

export default {
  name: "Home",
  data() {
    return {
      mouse_x_start: null,
      mouse_y_start: null,
      slide: 0,
      current_width: window.innerWidth,
      current_height: window.innerHeight,
      accordianItems: [
        { name: "What is Dorks?", text: "Dorks is a group of technical experts that provide friendly remote technical support. Dorks helps you or a loved one with just about any computer, phone, software or device." },
        { name: "How do I get support?", text: "Signup for Dorks by purchasing a monthly or annual plan. Then you can immediately submit your technical support requests by phone, email, or chat from 9am to 5pm PST." },
        { name: "Is there anything you can't do?", text: "Dorks provides remote support so we can't visit your home. We also don't fix any hardware or repair any physical devices. Finally, we don't offer any specialized software training." },
        { name: "Can I share my account?", text: "At this time you can buy Dorks for yourself or for a loved one. Pretty soon, we'll be launching Dorks for Families and Businesses. Email beta@dorks.co for early access or requests." },
      ]
    };
  },
  components: {
    Header,
    TopBanner,
    Footer,
    carousel,
    Accordian,
    DorksScroller
  },
  methods: {
    set_dims: function () {
      this.current_width = window.innerWidth;
      this.current_height = window.innerHeight;
    },
  },
  computed: {
    is_mobile: function () {
      // console.log(this.current_width, this.current_height);
      return this.current_width < 768;
    },
  },
  mounted() {
    window.addEventListener("resize", this.set_dims);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.set_dims);
    // console.log("Resize destroyed");
  },
};
</script>
